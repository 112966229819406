import { FC } from 'react';
import logo from './logo.svg';
import './App.scss';
import { Routes, Route } from "react-router-dom";
import Home from './routes/Home';
import Blogs from './routes/Blogs';
import About from './routes/About';

const App: FC = () => {
  return (
    <Routes>
      <Route index element={<Home />}/>
      <Route path="blogs" element={<Blogs />}/>
      <Route path="about" element={<About />}/>
    </Routes>
  );
}

export default App;
